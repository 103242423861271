import { css } from '@emotion/react';

const datePickerStyle = css`
  width: 100%;

  .react-datepicker-wrapper {
    display: flex;
  }
  &.field-form {
    margin-top: 0.8rem;
  }

  input {
    background: url('/assets/images/ic_datepicker_calendar.svg') no-repeat calc(100% - 1.2rem) center / 2.4rem;
    -o-background-size: calc(100% - 1.2rem) center / 2.4rem;
    -moz-background-size: calc(100% - 1.2rem) center / 2.4rem;
    -webkit-background-size: calc(100% - 1.2rem) center / 2.4rem;
  }
  .react-datepicker__month-container {
    box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 15%);
  }
  .react-datepicker-popper {
    z-index: 5;
    padding: 0 !important;
  }
  .react-datepicker__triangle {
    display: none !important;
  }
  .react-datepicker__day--selected {
    color: #fff !important;
  }
  .react-datepicker {
    font-size: 1.5rem;
    border: 1px solid #aeaeae00;
  }
  .react-datepicker__current-month {
    font-size: 1.5rem;
    height: 2rem;
    line-height: 2rem;
  }

  .react-datepicker__navigation--previous {
    position: absolute;
    left: 0;
  }
  .react-datepicker__navigation--next {
    position: absolute;
    right: 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 2.3rem;
    line-height: 2.3rem;
    text-align: center;
    margin: 0.166rem;
    font-size: 1.3rem;
  }

  .react-datepicker__day--disabled {
    color: #ccc;
  }
`;

export default datePickerStyle;
