import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const agreementCheckFormStyle = css`
  &.haefe-form {
    padding: 3.2rem 0 0 0;
    // border-top: 1px dashed rgb(221, 221, 221);
  }

  .field-area {
    margin: 0;
  }

  .agreement-box {
    padding: 0 1rem;
    width: 100%;

    .agreement {
      margin-top: 1.6rem;
      &:first-of-type {
        margin-top: 0;
      }
      .haefe-checkbox {
        width: 100%;
      }
      .sub-agreement-box {
        margin-top: 1.6rem;
        padding-left: 3.2rem;
        .sub-agreement {
          ${flex('row', 'flex-start', 'center')};
          .checkbox {
            margin-left: 3.6rem;
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  @media ${layout.$mobile} {
    label {
      ${flex('row', 'flex-start', 'flex-start')}
    }

    .agreement-box {
      padding: 0;
    }
  }
`;

export default agreementCheckFormStyle;
