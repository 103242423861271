import { Form } from '@/components/haeLibraryRefactored';
import { useContext } from 'react';
import { QUICK_MENU_FIELD_DATA } from '../../../../constants';
import QuickMenuModalContext from '../../../../context';

interface IQuickMenuFormItemProps {
  className?: string;
  name: keyof IQuickMenuMergedFormData;
  label?: React.ReactNode;
  children: React.ReactNode;
  [props: string]: unknown;
}

function QuickMenuFormItem({ className, name, label, children, ...props }: IQuickMenuFormItemProps) {
  const { modalType } = useContext(QuickMenuModalContext);

  const isRequired = QUICK_MENU_FIELD_DATA[modalType]?.requiredFields.includes(name);

  return (
    <Form.Item className={className} required={isRequired} label={label} name={name} {...props}>
      {children}
    </Form.Item>
  );
}

export default QuickMenuFormItem;
