import { Dropdown, Input } from '@/components/haeLibraryRefactored';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import useMember from '@/hooks/useIsMeber';
import { PREFERRED_TIME_TO_CALL } from '@/components/modules/regionSelectForm/constants';
import { useSelectBoxModel, useSelectBoxVariant } from '@/query/product/query';
import QuickMenuFormItem from '../contentsFormItem';
import classNames from 'classnames';
import DealerBlock from '@/components/modules/dealerBlock';
import { DropdownItem } from '@/components/haeLibraryRefactored/Dropdown/type';
import { getDayjsObjWithoutYMD } from '@/utils/getDayjsObjWithoutYMD';
import QuickMenuModalContext from '@/components/modules/quickMenu/components/modal/context';
import RegionSelectFormContents from '@/components/modules/regionSelectForm/components/contents';
import useFormInstance from '@/components/haeLibraryRefactored/Form/hooks/useFormInstance';
import { EMPLOYEE_AUTO_SELECT_ID, QUICK_MODAL_TYPE } from '@/components/modules/quickMenu/components/modal/constants';
import DealershipBlock from '@/components/modules/dealershipBlock';
import { currentConsultantState, currentDealerState } from '@/stores/common/user';
import { useRecoilValue } from 'recoil';
import { useGetDealerListByRegion } from '@/query/dealers/query';
import { DEALER_LIST_TYPE } from '@/components/modules/changeDealerModal/constants';
import { getValidModel } from '@/components/modules/quickMenu/components/modal';

interface IQuoteContentsProps {
  positionSuccessCallback: (position: GeolocationPosition) => void;
}

/**
 * @description : QuickMenu > Get A Quote
 */
function QuoteContents({ positionSuccessCallback }: IQuoteContentsProps) {
  const { t } = useTranslation();
  const [isMember] = useMember();

  const form = useFormInstance<IQuickMenuFormData>();
  const formData = form.getFieldsValueAll() ?? {};
  const { dealerId, province, model, district, subDistrict } = formData;
  const [isNewPosition, setIsNewPosition] = useState(false);

  const { data: modelSelectBoxData, isFetched: modelSelectBoxFetched } = useSelectBoxModel();
  const { data: variantSelectBoxData, isFetched: variantSelectBoxFetched } = useSelectBoxVariant({
    baseModelIds: modelSelectBoxData?.find(data => data.baseModelIds.some(baseModelId => baseModelId === Number(model)))
      ?.baseModelIds,
  });
  const { data: allDealerList, isFetched: AllDealerListFetched } = useGetDealerListByRegion({}, { enabled: true });

  const currentDealer = useRecoilValue(currentDealerState);
  const currentConsultant = useRecoilValue(currentConsultantState);

  const { modalType, dealerList: baseDealerList, consultantList, dealerListType } = useContext(QuickMenuModalContext);

  const modelList = modelSelectBoxData
    ?.filter(item => item.dealerIds.length !== 0)
    ?.map(item => ({
      value: item.baseModelIds?.[0],
      label: item.modelDisplayName,
    }));

  const variantList = variantSelectBoxData
    ?.filter(item => item.dealerIds.length !== 0)
    ?.map(item => ({
      value: item.productNo,
      label: item.variantName,
    }));

  const validDealerIds = useMemo(() => {
    if (!modelSelectBoxData) return null;

    const selectedModel = modelSelectBoxData.find(selectBoxModel => selectBoxModel.baseModelIds[0] === model);

    return selectedModel
      ? selectedModel.dealerIds
      : Array.from(new Set(modelSelectBoxData.flatMap(model => model.dealerIds)));
  }, [modelSelectBoxData, model]);

  const dealerList = useMemo(
    () => (validDealerIds ? baseDealerList.filter(dealer => validDealerIds.includes(dealer.id)) : baseDealerList),
    [baseDealerList, validDealerIds],
  );

  const allValidDealerList = validDealerIds
    ? allDealerList?.filter(dealer => validDealerIds.includes(dealer.id))
    : allDealerList;
  const provinceList = [...new Set(allValidDealerList?.map(dealer => dealer.province))];
  const districtList = [
    ...new Set(allValidDealerList?.filter(dealer => dealer.province === province).map(dealer => dealer.district)),
  ];
  const subDistrictList = [
    ...new Set(
      allValidDealerList
        ?.filter(dealer => dealer.province === province && dealer.district === district)
        .map(dealer => dealer.subDistrict),
    ),
  ];

  const dealerDataInfo: IDealerShipBlockProps = {
    dealerId: currentDealer?.id,
    dealerTitle: currentDealer?.name ?? '',
    location: `${currentDealer?.province ?? ''} ${currentDealer?.district ?? ''} ${currentDealer?.subDistrict ?? ''} ${
      currentDealer?.address ?? ''
    } ${currentDealer?.postalCode}`,
    dealerCall: currentDealer?.phoneNumber ?? '',
    time: `${getDayjsObjWithoutYMD(currentDealer?.openTime)?.format('hh:mmA')} - ${getDayjsObjWithoutYMD(
      currentDealer?.closeTime,
    )?.format('hh:mmA')}`,
    name: currentConsultant ? `${currentConsultant?.firstName ?? ''} ${currentConsultant?.lastName ?? ''}` : '-',
    consultantTitle: t('Label.SalesConsultant'),
    consultantCall: currentConsultant?.mobileNumber ?? '',
    email: currentConsultant?.emailAddress ?? '',
    lineId: currentConsultant?.lineId ?? '',
    lat: currentDealer?.latitude || 0,
    lng: currentDealer?.longitude || 0,
  };

  useEffect(() => {
    if (!dealerList || !validDealerIds || !modelSelectBoxFetched || !AllDealerListFetched) return;

    const newEmployeeId = currentDealer?.id === dealerList[0]?.id ? currentConsultant?.id : EMPLOYEE_AUTO_SELECT_ID;

    if (dealerList.length === 1 && dealerList[0]?.id !== dealerId) {
      form.setFieldsValue({
        ...formData,
        dealerId: dealerList[0]?.id,
        employeeId: newEmployeeId,
        province: dealerList[0]?.province,
        district: dealerList[0]?.district,
        subDistrict: dealerList[0]?.subDistrict,
        model: getValidModel(model, modelSelectBoxData),
      });

      return;
    }

    const isValidDealer = dealerList?.map(dealer => dealer.id).includes(dealerId);
    const isValidProvince = provinceList?.includes(province);
    const isValidDistrict = districtList?.includes(district);
    const isValidSubDistrict = subDistrictList?.includes(subDistrict);

    if (dealerListType === DEALER_LIST_TYPE.POSITION && isNewPosition) {
      form.setFieldsValue({
        ...formData,
        dealerId: dealerList[0]?.id,
        employeeId: newEmployeeId,
        province: dealerList[0]?.province,
        district: dealerList[0]?.district,
        subDistrict: null,
        model: getValidModel(model, modelSelectBoxData),
      });

      setIsNewPosition(false);

      return;
    }

    if (isValidDealer && isValidProvince && isValidDistrict && isValidSubDistrict) return;

    const newDealerData = {
      dealerId: isValidDealer ? dealerId : null,
      employeeId: isValidDealer ? formData.employeeId : EMPLOYEE_AUTO_SELECT_ID,
      province: isValidProvince ? province : null,
      district: isValidProvince && isValidDistrict ? district : null,
      subDistrict: isValidProvince && isValidDistrict && isValidSubDistrict ? subDistrict : null,
    };

    form.setFieldsValue({
      ...formData,
      ...newDealerData,
      model: getValidModel(model, modelSelectBoxData),
    });
  }, [dealerList, validDealerIds, modelSelectBoxFetched, AllDealerListFetched, variantSelectBoxFetched]);

  useEffect(() => {
    if (dealerListType === DEALER_LIST_TYPE.POSITION) setIsNewPosition(true);
  }, [dealerListType]);

  useEffect(() => {
    if (!modelSelectBoxFetched) return;
    if (!model || modelList.some(item => item.value === Number(model))) return;

    const targetBaseModelId = getValidModel(model, modelSelectBoxData);

    if (targetBaseModelId) {
      form.setFieldValue('model', targetBaseModelId);
    }
  }, [model, modelSelectBoxFetched]);

  return (
    <>
      <div className="form-wrapper">
        <div className="form-top-box">
          <legend className="hidden">
            <div className="title-box">
              <span className="title"></span>
            </div>
          </legend>
          <div className="required-noti-box">
            <span className="text">{t('Label.RequiredFields')}</span>
          </div>
        </div>
        <fieldset>
          <div className="field-container">
            <div className="field-area">
              <QuickMenuFormItem className="col-2" required label={t('Label.FirstName')} name="firstName">
                <Input placeholder={t('Label.EnterYourFirstName')} disabled={isMember} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" required label={t('Label.LastName')} name="lastName">
                <Input placeholder={t('Label.EnterYourLastName')} disabled={isMember} />
              </QuickMenuFormItem>
              <QuickMenuFormItem
                className="col-2"
                label={t('Label.MobilePhone')}
                name="mobile"
                validation={(val: string) => {
                  const status = (val.startsWith('0') && val.length >= 10) || val?.length === 0 ? 'success' : 'error';

                  return {
                    status,
                    message: status === 'error' && 'Invalid phone number',
                  };
                }}
                required
              >
                <Input type="number" placeholder={t('Label.EnterYourMobilePhone')} disabled={isMember} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" label={t('Label.Email')} name="email">
                <Input placeholder={t('Label.EnterYourEmail')} type="email" disabled={isMember} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" required name="model" label={t('Label.Model')}>
                <Dropdown
                  placeholder={t('Label.SelectAModel')}
                  items={modelList ?? []}
                  disabled={modalType === QUICK_MODAL_TYPE.CONTACT_DEALER}
                />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" required name="productNo" label={t('Label.Variant')}>
                <Dropdown
                  placeholder={t('Label.SelectAVariant')}
                  disabled={!model || modalType === QUICK_MODAL_TYPE.CONTACT_DEALER}
                  items={variantList ?? []}
                />
              </QuickMenuFormItem>
            </div>
          </div>
        </fieldset>
      </div>
      <div className="form-wrapper">
        <RegionSelectFormContents
          type={modalType}
          positionSuccessCallback={positionSuccessCallback}
          disabled={QUICK_MODAL_TYPE.CONTACT_DEALER === modalType}
          provinceList={provinceList}
          districtList={districtList}
          subDistrictList={subDistrictList}
          requiredFields={['province', 'district']}
        />
        <fieldset>
          <div className="field-container">
            <div className="field-area">
              {modalType === QUICK_MODAL_TYPE.QUOTE && (
                <>
                  <QuickMenuFormItem
                    className={classNames('col-2', 'dealer-field')}
                    name="dealerId"
                    label={t('Label.Dealer')}
                  >
                    <Dropdown
                      placeholder={t('Label.SelectADealer')}
                      items={dealerList}
                      itemRender={(item: DropdownItem) => (
                        <DealerBlock
                          key={item.id}
                          title={item.name}
                          noCallAction
                          call={item.phoneNumber}
                          location={`${item.province} ${item.district} ${item.subDistrict}`}
                          distance={item?.distance}
                          time={`${getDayjsObjWithoutYMD(item.openTime).format('hh:mmA')} - ${getDayjsObjWithoutYMD(
                            item.closeTime,
                          )?.format('hh:mmA')}`}
                          dealerCode={item?.dealerCode}
                          lat={item.latitude}
                          lng={item.longitude}
                        />
                      )}
                      disabled={!province || !district}
                    />
                  </QuickMenuFormItem>
                  <QuickMenuFormItem
                    className={classNames('col-2', 'employee-field')}
                    name="employeeId"
                    label={t('Label.SelectASalesConsultant')}
                  >
                    <Dropdown
                      placeholder={t('Label.SelectASalesConsultant')}
                      disabled={!dealerId}
                      items={consultantList}
                    />
                  </QuickMenuFormItem>
                </>
              )}
              {modalType === QUICK_MODAL_TYPE.CONTACT_DEALER && (
                <div className="dealership-block-wrapper">
                  <DealershipBlock className="dealership-block" {...dealerDataInfo} />
                </div>
              )}
              <QuickMenuFormItem
                className="col-2"
                name="preferredTimeToCall"
                label={t('QuickMenu.GetAQuotePreferredTimetoCall')}
              >
                <Dropdown placeholder={t('Label.SelectATime')} items={PREFERRED_TIME_TO_CALL} />
              </QuickMenuFormItem>
              <QuickMenuFormItem name="comment">
                <Input placeholder={t('Label.Comments')} type="textarea" maxByte={4000} showCount />
              </QuickMenuFormItem>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
}

export default QuoteContents;
