import { axiosInstance as axios } from '@/query/axios';

export async function requestQuote(params: RequestQuote.IRequestQuoteReq) {
  try {
    const response = await axios.post(`/order_api/request-quote`, { ...params }, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
}

export async function requestQuoteGuest(params: RequestQuote.IRequestQuoteGuestReq) {
  try {
    const response = await axios.post(`/order_api/request-quote/guest`, { ...params });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
}
