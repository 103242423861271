import { isProdOffConsole } from '@/config';
import { axiosInstance as axios } from '@/query/axios';

export const getVariants = async (params: Product.IVariantsReq): Promise<Product.TVariantsRes> => {
  try {
    const response = await axios.post(`/product_api/product/quotation/vehicle/variants`, params);

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getModels = async (params: Product.IModelsReq): Promise<Product.TModelsRes> => {
  try {
    const response = await axios.post(`/product_api/product/quotation/vehicle/models`, params);

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
export const getFilterVariants = async (params: Product.IFilterVariantsReq): Promise<Product.TVariantsRes> => {
  try {
    const response = await axios.post(`/product_api/product/filter/vehicle/variants`, params);

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getSelectBoxModels = async (params: Product.ISelectBoxModelsReq) => {
  try {
    const response = await axios.get(`/product_api/product/select-box/models`, { params });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getSelectBoxVariants = async (params: Product.ISelectBoxVariantsReq) => {
  try {
    const response = await axios.get(`/product_api/product/select-box/variants`, {
      params: { ...params, baseModelIds: params.baseModelIds.join(',') },
    });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getVariantsConfigure = async (
  params: Product.IVariantConfigureReq,
): Promise<Product.IVariantConfigureRes> => {
  try {
    const response = await axios.get(`/product_api/product/quotation/configure/vehicle/variants`, {
      params,
    });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getVariantDetail = async (productNo: number): Promise<Product.IVariantDetailRes> => {
  try {
    const response = await axios.get(`/product_api/product/quotation/configure/vehicle/variant/${productNo}`);

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getVariantColor = async ({
  type,
  productNo,
  ...params
}: Product.IExteriorColorReq | Product.IInteriorColorReq): Promise<Product.IETeriorColorRes[]> => {
  try {
    // const params = type === 'interior' ? { exteriorColorId } : {};
    const { data } = await axios.get<Product.IETeriorColorRes[]>(`/gateway/product/colors`, {
      params: {
        type,
        productNo,
        ...params,
      },
    });

    return data;
  } catch (err: any) {
    isProdOffConsole(`:::product::: Error ${err?.message}`);

    throw err;
  }
};

export const getSelectBoxModel = async (params: Product.ISelectBoxModelsReq): Promise<Product.TSelectBoxModelsRes> => {
  try {
    const response = await axios.get(`/product_api/product/select-box/models`, {
      params: params,
    });

    return response?.data || null;
  } catch (err) {
    throw err;
  }
};

/**
 * configuration 전체 조회 api
 */
export const getConfiguration = async (params: Product.TConfigurationReq): Promise<Product.IConfigurationRes> => {
  try {
    const { data } = await axios.get(`/gateway/product/configuration`, {
      params,
    });

    return data;
  } catch (err: any) {
    isProdOffConsole(`:::getConfiguration::: Error ${err?.message}`);

    throw err;
  }
};
