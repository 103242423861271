import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { requestQuote, requestQuoteGuest } from './api';

export function useRequestQuote(
  options?: Omit<UseMutationOptions<any, unknown, RequestQuote.IRequestQuoteReq, unknown>, 'mutationFn'>,
) {
  return useMutation((params: RequestQuote.IRequestQuoteReq) => requestQuote(params), { ...options });
}

export function useRequestQuoteGuest(
  options?: Omit<UseMutationOptions<any, unknown, RequestQuote.IRequestQuoteGuestReq, unknown>, 'mutationFn'>,
) {
  return useMutation((params: RequestQuote.IRequestQuoteGuestReq) => requestQuoteGuest(params), { ...options });
}
