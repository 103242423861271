import React, { useEffect, useMemo, useState } from 'react';
import {
  EMPLOYEE_AUTO_SELECT_ID,
  FOR_SERVICE_APPOINTMENT_TYPE,
  QUICK_MENU_FIELD_DATA,
  QUICK_MODAL_TYPE,
} from '../../constants';
import ContactUsContents from './components/contactUsContents';
import QuoteContents from './components/quoteContents';
import TradeInContents from './components/tradeInContents';
import TestDriveContents from './components/testDriveContents';
import ServiceAppointmentContents from './components/serviceAppointmentContents';
import QuickMenuModalContext from '../../context';
import {
  useDealersListByType,
  useGetDealerDetail,
  useGetDealersDistricts,
  useGetDealersProvinces,
  useGetDealersSubDistricts,
} from '@/query/dealers/query';
import { useRecoilValue } from 'recoil';
import { currentConsultantState, currentDealerState, selfSelectedDealerSelector } from '@/stores/common/user';
import { Form } from '@/components/haeLibraryRefactored';
import { FormInstance } from '@/components/haeLibraryRefactored/Form/type';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import { DEALER_LIST_TYPE } from '@/components/modules/changeDealerModal/constants';
import IoniqFormContents from './components/ioniqFormContents';
import { DEALER_RETAIL_TYPES } from '@/query/dealers/constants';

interface IQuickMenuModalFormContents {
  type: TQuickModalType;
  form: FormInstance<IQuickMenuFormData>;
}

function QuickMenuModalFormContents({ type, form }: IQuickMenuModalFormContents) {
  const { t } = useTranslation();

  const currentDealer = useRecoilValue(currentDealerState);
  const currentConsultant = useRecoilValue(currentConsultantState);
  const selfSelectedDealer = useRecoilValue(selfSelectedDealerSelector);

  const [listType, setListType] = useState<TDealerListType>(DEALER_LIST_TYPE.REGION);
  const [currentPosition, setCurrentPosition] = useState(null);

  const { province, district, subDistrict, dealerId } = form.getFieldsValueAll();

  const currentRegion = useMemo(
    () => ({
      province: province,
      district: district,
      subDistrict: subDistrict,
    }),
    [province, district, subDistrict],
  );
  const isServiceAppointment = type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT;

  const needRegion = type !== QUICK_MODAL_TYPE.TEST_DRIVE && type !== QUICK_MODAL_TYPE.QUOTE;

  const dealersCondition = (condition: any) => {
    const { DEALER_BUSINESS, DISTRICT, SUB_DISTRICT, DEALER_DETAIL } = FOR_SERVICE_APPOINTMENT_TYPE;
    const params: any = {};

    if (condition === DISTRICT || condition === SUB_DISTRICT) {
      needRegion ? (params.district = district) : null;
    }

    if (condition === DEALER_DETAIL) {
      params.id = dealerId;
    } else {
      needRegion ? (params.province = province) : null;
    }
    isServiceAppointment ? (params.dealerBusinessType = DEALER_BUSINESS) : null;

    return params;
  };

  const { data: provinceData } = useGetDealersProvinces(
    dealersCondition(FOR_SERVICE_APPOINTMENT_TYPE.DEALER_PROVINCES),
  );

  const { data: districtData } = useGetDealersDistricts(dealersCondition(FOR_SERVICE_APPOINTMENT_TYPE.DISTRICT));
  const { data: subDistrictData } = useGetDealersSubDistricts(
    dealersCondition(FOR_SERVICE_APPOINTMENT_TYPE.SUB_DISTRICT),
  );

  const { data: baseDealerList, isFetched } = useDealersListByType({
    isPosition: listType === DEALER_LIST_TYPE.POSITION,
    region: currentRegion,
    position: {
      latitude: currentPosition?.lat,
      longitude: currentPosition?.lng,
      limit: 5,
    },
    enabled: true,
    excludeDealershipRetailType:
      type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT ? [DEALER_RETAIL_TYPES.AGENCY_DEALER] : null,
    dealerBusinessType:
      type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT ? FOR_SERVICE_APPOINTMENT_TYPE.DEALER_BUSINESS : null,
  });

  const [dealerList, setDealerList] = useState<Dealers.IDealersRes[]>(null);

  useEffect(() => {
    if (!isFetched) return;

    setDealerList(baseDealerList);
  }, [baseDealerList, isFetched]);

  const positionSuccessCallback = (position: GeolocationPosition) => {
    const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };

    setCurrentPosition(latlng);
    setListType(DEALER_LIST_TYPE.POSITION);
  };

  useEffect(() => {
    if (type === QUICK_MODAL_TYPE.TEST_DRIVE || type === QUICK_MODAL_TYPE.QUOTE) return;

    if (listType === DEALER_LIST_TYPE.POSITION && dealerList) {
      const newDealer = dealerList[0];
      const newFormData = _.omit(form.getFieldsValueAll(), ['district', 'subDistrict']);
      const newEmployeeId = currentDealer?.id === newDealer?.id ? currentConsultant?.id : EMPLOYEE_AUTO_SELECT_ID;

      form.setFieldsValue({
        ...newFormData,
        province: newDealer.province,
        district: newDealer.district,
        subDistrict: null,
        dealerId: newDealer.id,
        employeeId: newEmployeeId,
      });
    }
  }, [dealerList]);

  const hasAutoSelect = currentDealer?.id !== form.getFieldValue('dealerId') || !currentConsultant?.id;

  const dealerSelectItems: (Partial<Dealers.TDealerEntity> & { value: number; label: string })[] = useMemo(
    () =>
      dealerList?.map(item => ({
        value: item.id,
        label: item.name,
        ...item,
      })) ?? (selfSelectedDealer ? [{ value: currentDealer?.id, label: currentDealer?.name, ...currentDealer }] : null),
    [dealerList],
  );

  const { data: dealerDetailData } = useGetDealerDetail(dealersCondition(FOR_SERVICE_APPOINTMENT_TYPE.DEALER_DETAIL));

  const consultantsOfSelectedDealer =
    dealerDetailData?.dealer?.employees?.map(item => ({
      value: item.id,
      label: `${item.firstName ?? ''} ${item.lastName ?? ''}`,
    })) ?? [];

  const salesConsultants = !hasAutoSelect
    ? consultantsOfSelectedDealer
    : [{ value: EMPLOYEE_AUTO_SELECT_ID, label: t('ClickToBuy.AutoSelect') }, ...consultantsOfSelectedDealer];

  const [prevMobile, setPrevMobile] = useState('');

  const handleFormChange = (
    values: Partial<IQuickMenuFormData>,
    _0: Partial<IQuickMenuFormData>,
    newValues: Partial<IQuickMenuFormData>,
  ) => {
    const name = Object.keys(values)?.[0];

    if (name === 'mobile') {
      const mobileReg = /^[0-9]+$/;
      const mobile = Object.values(values)?.[0] as string;

      if (mobile && mobile?.length !== 0 && (!mobileReg.test(mobile) || mobile?.length > 10)) {
        form.setFieldValue('mobile', prevMobile);

        return;
      } else {
        setPrevMobile(mobile);
      }
    }

    const isDealerByLocation = name === 'dealerId' && listType === DEALER_LIST_TYPE.POSITION;

    if (['province', 'district', 'subDistrict'].includes(name)) {
      setListType('region');
    }

    const resetTarget =
      QUICK_MENU_FIELD_DATA[type].relations.find(relation => relation.condition === name)?.targets ?? [];

    const newFormData: IQuickMenuFormData = _.omit(newValues, resetTarget);
    const isPrevSelectedDealer = currentDealer.id === newFormData.dealerId;
    const isEmployeeSelected = !!newFormData.employeeId;

    if (!isEmployeeSelected)
      newFormData.employeeId =
        isPrevSelectedDealer && currentConsultant?.id ? currentConsultant?.id : EMPLOYEE_AUTO_SELECT_ID;

    if (isDealerByLocation) {
      const targetDealer = dealerSelectItems.find(dealer => dealer.value === newFormData.dealerId);

      newFormData.province = targetDealer.province;
      newFormData.district = targetDealer.district;
    }

    form.setFieldsValue(newFormData);
  };

  return (
    <QuickMenuModalContext.Provider
      value={{
        modalType: type,
        dealerListType: listType,
        dealerList: dealerSelectItems ?? [],
        consultantList: salesConsultants,
        provinceList: provinceData?.provinceList ?? null,

        districtList: districtData?.districtList ?? null,
        subDistrictList: subDistrictData?.subDistrictList ?? null,
      }}
    >
      <Form form={form} initialValues={form.getFieldsValueAll() ?? {}} onChange={handleFormChange}>
        {type === QUICK_MODAL_TYPE.CONTACT_US && (
          <ContactUsContents positionSuccessCallback={positionSuccessCallback} />
        )}
        {type === QUICK_MODAL_TYPE.QUOTE && <QuoteContents positionSuccessCallback={positionSuccessCallback} />}
        {type === QUICK_MODAL_TYPE.CONTACT_DEALER && (
          <QuoteContents positionSuccessCallback={positionSuccessCallback} />
        )}
        {type === QUICK_MODAL_TYPE.TRADE_IN && <TradeInContents positionSuccessCallback={positionSuccessCallback} />}

        {type === QUICK_MODAL_TYPE.TEST_DRIVE && (
          <TestDriveContents positionSuccessCallback={positionSuccessCallback} />
        )}

        {type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT && (
          <ServiceAppointmentContents positionSuccessCallback={positionSuccessCallback} />
        )}
        {type.includes('ioniq') && <IoniqFormContents />}
      </Form>
    </QuickMenuModalContext.Provider>
  );
}

export default QuickMenuModalFormContents;
