import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const quickMenuModalStyle = css`
  .haefe-form {
    padding: 0 0 3.2rem;
  }

  .region-selectform {
    padding: 3.2rem 0;

    .form-top-box.noti {
      display: none;
    }
  }

  .form-wrapper {
    border-top: 1px dashed ${colors.$color_ddd};
    &:first-of-type {
      padding: 0px 0px 3.2rem;
      border: none;
    }
  }

  .agreement-form {
    padding: 3.2rem 0 0 0;
    border-top: 1px dashed ${colors.$color_ddd};
  }

  .region-selectform {
    border-top: none;

    .form-top-box.noti {
      display: none;
    }
  }

  form {
    &:first-of-type {
      .form-top-box {
        margin: 0;
      }
    }
  }

  .haefe-textarea-wrapper {
    margin-bottom: 0;
  }
  // .dealer-field {
  //   &.service-reservation-dealer {
  //     margin-right: 50%;
  //   }
  // }

  .form-top-box {
    ${flex('row', 'space-between', 'flex-start')};
  }

  .form-top-box + .form-top-box {
    margin-top: 2.8rem !important;
  }

  .dealer-block {
    width: auto;
  }

  .dealership-block-wrapper {
    margin-top: 3.2rem;
    width: 100%;

    &:before {
      content: '';
      display: block;
      border-top: 1px dashed ${colors.$color_ddd};
      height: 3.2rem;
    }

    &:after {
      content: '';
      display: block;
      border-bottom: 1px dashed ${colors.$color_ddd};
      height: 3.2rem;
    }
  }

  fieldset > * {
    height: 100%;
  }

  .field-form {
    padding: 0;
  }

  .btn-use-location {
    ${flex('row', 'center', 'center')};
    ${font('1.4rem', 400, colors.$color_000, '2.2rem')};
    .ic-location-b {
      margin-right: 0.4rem;
    }
    span {
      text-decoration-line: underline;
      text-underline-position: under;
    }
  }

  .dealer-block-button {
    width: 100%;
    margin-top: 1.6rem;
    border: 1px solid ${colors.$color_ddd};
  }

  .dealer-field {
    .haefe-dropdown-item {
      border: 1px solid ${colors.$color_ddd};
      .dealer-block {
        width: 100%;
        padding: 1.5rem;
      }

      &.haefe-dropdown-item-selected {
        border: 2px solid ${colors.$secondary1};
        &:after {
          content: '';
          display: block;
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1.6rem;
          background: url('/assets/images/icon_dropdown_selected.svg');
        }
      }
      // TODO: THAIONSALE-1954 doty 이미지 추가용 css 수정
      position: relative;
      .dealer-block-bottom {
        position: unset;
      }
      .doty-box {
        right: 1.5rem;
        bottom: 1.5rem;
      }
    }

    &.isHidden {
      display: none;
    }
  }

  .employee-field {
    &.isHidden {
      display: none;
    }
  }

  .haefe-textarea-wrapper {
    width: 100%;
    margin: 0;
  }

  .haefe-formitem.col-1 {
    .haefe-dropdown {
      max-width: unset;
    }
  }

  @media ${layout.$mobile} {
    .form-top-box {
      position: relative;

      legend .desc {
        margin-top: 1.2rem;
      }
    }

    .field-box {
      ${flex('column', 'space-between', 'flex-end')};

      // margin-top: 0;
    }

    .form-top-box + fieldset {
      margin-top: 3rem;
    }

    .location-button-box,
    .location-box {
      position: absolute;
      top: 0;
      right: 0;
      height: auto;
    }

    .dealership-block {
      border: none;
    }
  }
`;

export default quickMenuModalStyle;

export const consultantMessageStyle = css`
  .consultant-name {
    color: ${colors.$secondary1};
    padding: 10px 0;
  }
`;
