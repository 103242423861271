import { EMPLOYEE_AUTO_SELECT_ID, QUICK_MODAL_TYPE } from '@/components/modules/quickMenu/components/modal/constants';
import { useContactUs, useContactUsGuest } from '@/query/contactUs/query';
import { useRequestQuote, useRequestQuoteGuest } from '@/query/requestQuote/query';
import { useRequestTestDrive, useRequestTestDriveGuest } from '@/query/testdrive/query';
import { useRequestTradeIn, useRequestTradeInGuest } from '@/query/tradeIn/query';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import useMember from './useIsMeber';
import _ from 'lodash';
import { useRequestServiceAppointment, useRequestServiceAppointmentGuest } from '@/query/serviceAppointment/query';

export const useLeadMutation = (
  mutationOptions: TUseLeadMutationOptions,
  type: TQuickModalType,
): TUseLeadMutationResult => {
  const [isMember] = useMember();

  const useQuoteHook = isMember ? useRequestQuote : useRequestQuoteGuest;
  const useContactUsHook = isMember ? useContactUs : useContactUsGuest;
  const useTradeInHook = isMember ? useRequestTradeIn : useRequestTradeInGuest;
  const useTestDriveHook = isMember ? useRequestTestDrive : useRequestTestDriveGuest;
  const useServiceAppointmentHook = isMember ? useRequestServiceAppointment : useRequestServiceAppointmentGuest;

  const getMutateForType = (type: TQuickModalType) => {
    switch (type) {
      case QUICK_MODAL_TYPE.QUOTE:
        return useQuoteHook;
      case QUICK_MODAL_TYPE.TEST_DRIVE:
        return useTestDriveHook;
      case QUICK_MODAL_TYPE.TRADE_IN:
        return useTradeInHook;
      case QUICK_MODAL_TYPE.IONIQ_LAB:
      case QUICK_MODAL_TYPE.CONTACT_US:
        return useContactUsHook;
      case QUICK_MODAL_TYPE.CONTACT_DEALER: // contact dealer는 quote 폼 그대로 사용
        return useQuoteHook;
      case QUICK_MODAL_TYPE.SERVICE_APPOINTMENT:
        return useServiceAppointmentHook;
      case QUICK_MODAL_TYPE.IONIQ_BASIC:
        return useTestDriveHook;
      case QUICK_MODAL_TYPE.IONIQ_LONG_DISTANCE:
        return useTestDriveHook;
      case QUICK_MODAL_TYPE.IONIQ_ONE_DAY:
        return useTestDriveHook;
    }

    return useMutation;
  };

  const getSubmitData = (
    type: TQuickModalType,
    filteredFormData: IQuickMenuMergedFormData = {},
  ): TQuickModalMutateReq | TQuickModalGuestMutateReq => {
    const omitTargets = isMember ? ['firstName', 'lastName', 'email', 'mobile'] : [];

    const submitData: { mobileNumber?: string; mobilePhone?: string } & IQuickMenuMergedFormData = _.omitBy(
      filteredFormData,
      (value: any, key: string) =>
        _.isNil(value) || value.length === 0 || omitTargets.includes(key) || key === 'mobile',
    );

    if (!isMember)
      submitData[
        type === QUICK_MODAL_TYPE.QUOTE || type === QUICK_MODAL_TYPE.CONTACT_DEALER ? 'mobileNumber' : 'mobilePhone'
      ] = filteredFormData?.mobile;

    if (submitData.employeeId === EMPLOYEE_AUTO_SELECT_ID) submitData.employeeId = null;

    switch (type) {
      case QUICK_MODAL_TYPE.IONIQ_BASIC:
      case QUICK_MODAL_TYPE.IONIQ_ONE_DAY:
      case QUICK_MODAL_TYPE.IONIQ_LONG_DISTANCE:
      case QUICK_MODAL_TYPE.TEST_DRIVE:
        return {
          ..._.omit(submitData, ['model']),
          preferredDate: filteredFormData?.preferredDate
            ? dayjs(filteredFormData?.preferredDate)?.format('YYYY-MM-DDTHH:mm:ss.sssZ')
            : null,
          baseModelId: filteredFormData?.model,
        } as TQuickModalMutateReq | TQuickModalGuestMutateReq;

      case QUICK_MODAL_TYPE.TRADE_IN:
        return {
          ..._.omit(submitData, ['tradeInModel']),
          model: filteredFormData?.tradeInModel,
        } as TQuickModalMutateReq | TQuickModalGuestMutateReq;

      case QUICK_MODAL_TYPE.SERVICE_APPOINTMENT:
        const { preferredTime, preferredDate } = filteredFormData;
        const time = Number(preferredTime);
        const mergedDate = dayjs(new Date(preferredDate)).hour(time).minute(0).tz('Asia/Bangkok').format();
        const period = time < 12 ? 'AM' : 'PM';

        return {
          ..._.omit(submitData, ['tradeInModel']),
          preferredDate: mergedDate,
          preferredTime: `${period} ${time}:00`,
        } as TQuickModalMutateReq | TQuickModalGuestMutateReq;
    }

    return submitData as TQuickModalMutateReq | TQuickModalGuestMutateReq;
  };

  const useMutateFunc = getMutateForType(type);
  const mutationResult = useMutateFunc(mutationOptions);

  const customMutate = (mergedFormData: IQuickMenuMergedFormData, options: TUseLeadMutateOptions) => {
    const requestData = getSubmitData(type, mergedFormData);
    const { mutate } = mutationResult;

    if (isMember) {
      switch (type) {
        case QUICK_MODAL_TYPE.QUOTE:
          const quoteRequest = requestData as TQuickModalReqByType<'quote'>;
          const quoteOptions = options as TUseLeadMutateOptions<TQuickModalReqByType<'quote'>>;
          const quoteMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalReqByType<'quote'>>;

          quoteMutateFunc(quoteRequest, quoteOptions);

          break;
        case QUICK_MODAL_TYPE.TEST_DRIVE:
          const testDriveRequest = requestData as TQuickModalReqByType<'testDrive'>;
          const testDriveOptions = options as TUseLeadMutateOptions<TQuickModalReqByType<'testDrive'>>;
          const testDriveMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalReqByType<'testDrive'>>;

          testDriveMutateFunc(testDriveRequest, testDriveOptions);

          break;
        case QUICK_MODAL_TYPE.TRADE_IN:
          const tradeInRequest = requestData as TQuickModalReqByType<'tradeIn'>;
          const tradeInOptions = options as TUseLeadMutateOptions<TQuickModalReqByType<'tradeIn'>>;
          const tradeInMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalReqByType<'tradeIn'>>;

          tradeInMutateFunc(tradeInRequest, tradeInOptions);

          break;
        case QUICK_MODAL_TYPE.CONTACT_US:
          const contactUsRequest = requestData as TQuickModalReqByType<'contactUs'>;
          const contactUsOptions = options as TUseLeadMutateOptions<TQuickModalReqByType<'contactUs'>>;
          const contactUsMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalReqByType<'contactUs'>>;

          contactUsMutateFunc(contactUsRequest, contactUsOptions);

          break;
        case QUICK_MODAL_TYPE.IONIQ_BASIC:
        case QUICK_MODAL_TYPE.IONIQ_ONE_DAY:
        case QUICK_MODAL_TYPE.IONIQ_LONG_DISTANCE:
        case QUICK_MODAL_TYPE.SERVICE_APPOINTMENT:
          const serviceAppointmentRequest = requestData as TQuickModalReqByType<'serviceAppointment'>;
          const serviceAppointmentOptions = options as TUseLeadMutateOptions<
            TQuickModalReqByType<'serviceAppointment'>
          >;
          const serviceAppointmentMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalReqByType<'serviceAppointment'>>;

          serviceAppointmentMutateFunc(serviceAppointmentRequest, serviceAppointmentOptions);

          break;
      }
    } else {
      switch (type) {
        case QUICK_MODAL_TYPE.QUOTE:
          const quoteRequest = requestData as TQuickModalGuestReqByType<'quote'>;
          const quoteOptions = options as TUseLeadMutateOptions<TQuickModalGuestReqByType<'quote'>>;
          const quoteMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalGuestReqByType<'quote'>>;

          quoteMutateFunc(quoteRequest, quoteOptions);

          break;
        case QUICK_MODAL_TYPE.IONIQ_BASIC:
        case QUICK_MODAL_TYPE.IONIQ_ONE_DAY:
        case QUICK_MODAL_TYPE.IONIQ_LONG_DISTANCE:
        case QUICK_MODAL_TYPE.TEST_DRIVE:
          const testDriveRequest = requestData as TQuickModalGuestReqByType<'testDrive'>;
          const testDriveOptions = options as TUseLeadMutateOptions<TQuickModalGuestReqByType<'testDrive'>>;
          const testDriveMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalGuestReqByType<'testDrive'>>;

          testDriveMutateFunc(testDriveRequest, testDriveOptions);

          break;
        case QUICK_MODAL_TYPE.TRADE_IN:
          const tradeInRequest = requestData as TQuickModalGuestReqByType<'tradeIn'>;
          const tradeInOptions = options as TUseLeadMutateOptions<TQuickModalGuestReqByType<'tradeIn'>>;
          const tradeInMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalGuestReqByType<'tradeIn'>>;

          tradeInMutateFunc(tradeInRequest, tradeInOptions);

          break;
        case QUICK_MODAL_TYPE.IONIQ_LAB:
        case QUICK_MODAL_TYPE.CONTACT_US:
          const contactUsRequest = requestData as TQuickModalGuestReqByType<'contactUs'>;
          const contactUsOptions = options as TUseLeadMutateOptions<TQuickModalGuestReqByType<'contactUs'>>;
          const contactUsMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalGuestReqByType<'contactUs'>>;

          contactUsMutateFunc(contactUsRequest, contactUsOptions);

          break;

        // contactDealer는 quote form 가져와서 사용(비회원인 경우만 보여주는 모달)
        case QUICK_MODAL_TYPE.CONTACT_DEALER:
          const contactDealerRequest = requestData as TQuickModalGuestReqByType<'quote'>;
          const contactDealerOptions = options as TUseLeadMutateOptions<TQuickModalGuestReqByType<'quote'>>;
          const contactDealerMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalGuestReqByType<'quote'>>;

          contactDealerMutateFunc(contactDealerRequest, contactDealerOptions);

          break;

        case QUICK_MODAL_TYPE.SERVICE_APPOINTMENT:
          const serviceAppointmentRequest = requestData as TQuickModalReqByType<'serviceAppointment'>;
          const serviceAppointmentOptions = options as TUseLeadMutateOptions<
            TQuickModalReqByType<'serviceAppointment'>
          >;
          const serviceAppointmentMutateFunc = mutate as TUseLeadMutateFunc<TQuickModalReqByType<'serviceAppointment'>>;

          serviceAppointmentMutateFunc(serviceAppointmentRequest, serviceAppointmentOptions);

          break;
      }
    }
  };

  return { ...mutationResult, mutate: customMutate };
};
