import { css } from '@emotion/react';
import { layout } from 'styles/mixin';

const ServiceAppointmentContentsStyle = css`
  .dealer-field {
    width: 100%;
    margin-right: calc(50% + 0.8rem);
  }

  @media ${layout.$mobile} {
    .dealer-field {
      margin-right: 0;
    }
  }
`;

export default ServiceAppointmentContentsStyle;
