import { Dropdown, Input } from '@/components/haeLibraryRefactored';
import React, { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import useMember from '@/hooks/useIsMeber';
import { PREFERRED_TIME_TO_CALL } from '@/components/modules/regionSelectForm/constants';
import {
  useTradeInBrand,
  useTradeInFuelType,
  useTradeInModel,
  useTradeInTransmission,
  useTradeInVariant,
} from '@/query/tradeInVehicle/query';
import DatePicker from '@/components/units/datePicker';
import QuickMenuFormItem from '../contentsFormItem';
import classNames from 'classnames';
import { getDayjsObjWithoutYMD } from '@/utils/getDayjsObjWithoutYMD';
import { DropdownItem } from '@/components/haeLibraryRefactored/Dropdown/type';
import DealerBlock from '@/components/modules/dealerBlock';
import QuickMenuModalContext from '../../../../context';
import RegionSelectFormContents from '@/components/modules/regionSelectForm/components/contents';
import useFormInstance from '@/components/haeLibraryRefactored/Form/hooks/useFormInstance';

interface ITradeInContentsProps {
  positionSuccessCallback: (position: GeolocationPosition) => void;
}

function TradeInContents({ positionSuccessCallback }: ITradeInContentsProps) {
  const { t } = useTranslation();
  const [isMember] = useMember();

  const form = useFormInstance<IQuickMenuFormData>();

  const { dealerId, province, district, brand, tradeInModel, variant, fuelType } = form.getFieldsValueAll() ?? {};

  const { data: brandData } = useTradeInBrand();
  const { data: modelData } = useTradeInModel({ brand: brand });
  const { data: variantData } = useTradeInVariant({ brand: brand, model: tradeInModel });
  const { data: fuelTypeData } = useTradeInFuelType({ brand: brand, model: tradeInModel, variant: variant });
  const { data: transmissionData } = useTradeInTransmission({
    brand: brand,
    model: tradeInModel,
    variant: variant,
    fuelType: fuelType,
  });

  const { modalType, dealerList, consultantList, provinceList, districtList, subDistrictList } =
    useContext(QuickMenuModalContext);

  return (
    <>
      <div className="form-wrapper">
        <div className="form-top-box">
          <legend className="hidden">
            <div className="title-box">
              <span className="title"></span>
            </div>
          </legend>
          <div className="required-noti-box">
            <span className="text">{t('Label.RequiredFields')}</span>
          </div>
        </div>
        <fieldset>
          <div className="field-container">
            <div className="field-area">
              <QuickMenuFormItem className="col-2" required label={t('Label.FirstName')} name="firstName">
                <Input placeholder={t('Label.EnterYourFirstName')} disabled={isMember} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" required label={t('Label.LastName')} name="lastName">
                <Input placeholder={t('Label.EnterYourLastName')} disabled={isMember} />
              </QuickMenuFormItem>
              <QuickMenuFormItem
                className="col-2"
                label={t('Label.MobilePhone')}
                name="mobile"
                validation={(val: string) => {
                  const status = (val.startsWith('0') && val.length >= 10) || val?.length === 0 ? 'success' : 'error';

                  return {
                    status,
                    message: status === 'error' && 'Invalid phone number',
                  };
                }}
                required
              >
                <Input type="number" placeholder={t('Label.EnterYourMobilePhone')} disabled={isMember} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" label={t('Label.Email')} name="email">
                <Input placeholder={t('Label.EnterYourEmail')} type="email" disabled={isMember} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" label={t('Label.RegistrationDate')} name="registrationDate">
                <DatePicker />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" label={t('Label.TradeInPlateNumber')} name="plate">
                <Input placeholder={t('Label.EnterPlate')} />
              </QuickMenuFormItem>
            </div>
          </div>
        </fieldset>
      </div>
      <div className="form-wrapper">
        <RegionSelectFormContents
          type={modalType}
          positionSuccessCallback={positionSuccessCallback}
          provinceList={provinceList}
          districtList={districtList}
          subDistrictList={subDistrictList}
          requiredFields={['province', 'district']}
        />
        <fieldset>
          <div className="field-container">
            <div className="field-area">
              <QuickMenuFormItem
                className={classNames('col-2', 'dealer-field')}
                name="dealerId"
                label={t('Label.Dealer')}
              >
                <Dropdown
                  placeholder={t('Label.SelectADealer')}
                  items={dealerList}
                  itemRender={(item: DropdownItem) => (
                    <DealerBlock
                      key={item.id}
                      title={item.name}
                      noCallAction
                      call={item.phoneNumber}
                      location={`${item.province} ${item.district} ${item.subDistrict}`}
                      distance={item?.distance}
                      time={`${getDayjsObjWithoutYMD(item.openTime).format('hh:mmA')} - ${getDayjsObjWithoutYMD(
                        item.closeTime,
                      )?.format('hh:mmA')}`}
                      dealerCode={item?.dealerCode}
                      lat={item.latitude}
                      lng={item.longitude}
                    />
                  )}
                  disabled={!province || !district}
                />
              </QuickMenuFormItem>
              <QuickMenuFormItem
                className={classNames('col-2', 'employee-field')}
                name="employeeId"
                label={t('Label.SelectASalesConsultant')}
              >
                <Dropdown placeholder={t('Label.SelectASalesConsultant')} disabled={!dealerId} items={consultantList} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-3" required name="brand" label={t('Brand')}>
                <Dropdown
                  placeholder={t('SelectABrand')}
                  disabled={!dealerId}
                  items={brandData?.brandList?.map(brand => ({ value: brand, label: brand })) ?? []}
                />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-3" required name="tradeInModel" label={t('Label.Model')}>
                <Dropdown
                  placeholder={t('Label.SelectAModel')}
                  disabled={!brand}
                  items={modelData?.modelList?.map(item => ({
                    value: item,
                    label: item,
                  }))}
                />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-3" required name="variant" label={t('Label.Variant')}>
                <Dropdown
                  placeholder={t('Label.SelectAVariant')}
                  disabled={!tradeInModel}
                  items={
                    variantData?.variantList?.map(item => ({
                      value: item,
                      label: item,
                    })) ?? [{ value: 1, content: 1 }]
                  }
                />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-3" required name="fuelType" label={t('Label.FuelType')}>
                <Dropdown
                  placeholder={t('Label.SelectAFuelType')}
                  disabled={!variant}
                  items={fuelTypeData?.fuleTypeList?.map(item => ({ value: item, label: item }))}
                />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-3" required name="transmission" label={t('Label.Transmission')}>
                <Dropdown
                  placeholder={t('Label.SelectATransmission')}
                  disabled={!fuelType}
                  items={transmissionData?.transmissionList?.map(item => ({ value: item, label: item }))}
                />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-3" name="mileage" label={t('Label.Mileage_KM')} required>
                <Input type="number" placeholder={t('Label.EnterMileage')} min={0} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" name="preferredTimeToCall" label={t('Label.PreferredTimeToCall')}>
                <Dropdown placeholder={t('Label.SelectATime')} items={PREFERRED_TIME_TO_CALL} />
              </QuickMenuFormItem>
              <QuickMenuFormItem name="comment">
                <Input placeholder={t('Label.Comments')} type="textarea" maxByte={4000} showCount />
              </QuickMenuFormItem>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
}

export default TradeInContents;
