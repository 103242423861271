import React from 'react';
import agreementCheckFormStyle from '@/components/modules/quickMenu/components/modal/components/agreementForm/style';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Checkbox, Form } from '@components/haeLibraryRefactored';
import { AnyObj } from '@/components/haeLibraryRefactored/type';

interface IAgreementCheckFormProps {
  handleChange: (values: AnyObj | null, name?: string[], omitTargets?: string[]) => void;
}

function AgreementCheckForm({ handleChange: handleChangeProp }: IAgreementCheckFormProps) {
  const { t } = useTranslation();
  const form = Form.useForm();
  const router = useRouter();

  const handleChange = (changedValue: Partial<IAgreementFormData>) => {
    if (typeof handleChangeProp === 'function') handleChangeProp(changedValue);
  };

  return (
    <Form form={form} onChange={handleChange} css={agreementCheckFormStyle} className="agreement-form">
      <fieldset>
        <div className="field-container">
          <div className="field-area">
            <div className="agreement-box">
              <div className="agreement">
                <Form.Item name="marketingCommunicationAgree">
                  <Checkbox
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('Agreement.AgreementReceiveCommunication'),
                        }}
                      />
                    }
                  />
                </Form.Item>
              </div>
              <div className="agreement">
                <Form.Item name="tcpAgree">
                  <Checkbox
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('Agreement.IAcceptTheTerms', {
                            termsLink: `${process.env.NEXT_PUBLIC_WWN_HOST}/${router.query.lang}/utility/terms-conditions`,
                            policyLink: `${process.env.NEXT_PUBLIC_WWN_HOST}/${router.query.lang}/utility/privacy-policy`,
                          }),
                        }}
                      />
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </Form>
  );
}

export default AgreementCheckForm;
