import React, { useState, PropsWithChildren, forwardRef, useRef } from 'react';
import DatePickerWrapper from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import datePickerStyle from './style';
import { Input } from '@/components/haeLibraryRefactored';
import { IDatePickerProps } from './type';

const DatePicker = ({
  children,
  className,
  onChange = () => null,
  value,
  minDate,
  ...otherProps
}: PropsWithChildren<IDatePickerProps>) => {
  const { t } = useTranslation('common');

  // date custom
  const [startDate, setStartDate] = useState(value ?? null);

  const handleDateChange = (date: Date) => {
    const newDate = dayjs(date)?.format('YYYY-MM-DD');

    if (!dayjs(date)?.isValid()) {
      setStartDate(null);

      return;
    }

    setStartDate(date);
    onChange(newDate);
  };

  const inputRef = useRef(null);

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => {
    // const ExampleCustomInput = ({ value, onClick }: any) => {
    return (
      <Input
        placeholder={t('Label.SelectADate')}
        readOnly
        onClick={onClick}
        value={dayjs(startDate)?.isValid() ? dayjs(startDate)?.format('YYYY-MM-DD') : ''}
        name={otherProps?.name}
        className={className}
        {...otherProps}
      />
    );
  });

  return (
    <div css={datePickerStyle} className={className}>
      <DatePickerWrapper
        minDate={minDate}
        selected={startDate}
        onChange={handleDateChange}
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
};

export default DatePicker;
