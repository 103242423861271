import { axiosInstance as axios } from '@/query/axios';

export const RequestServiceAppointmentGuest = async (
  params: ServiceAppointment.IRequestServiceAppointmentReq,
): Promise<any> => {
  try {
    const response = await axios.post(`/user_api/service-appointment/guest`, { ...params });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const RequestServiceAppointment = async (
  params: ServiceAppointment.IRequestServiceAppointmentReq,
): Promise<any> => {
  try {
    const response = await axios.post(`/user_api/service-appointment`, { ...params }, { needsAuth: true });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
