import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { RequestServiceAppointmentGuest, RequestServiceAppointment } from './api';

// TODO: 정비예약 api 경로 나오면 해당 경로에 맞는 파일로 이동
export function useRequestServiceAppointment(
  options?: Omit<
    UseMutationOptions<any, unknown, ServiceAppointment.IRequestServiceAppointmentReq, unknown>,
    'mutationFn'
  >,
) {
  return useMutation((params: ServiceAppointment.IRequestServiceAppointmentReq) => RequestServiceAppointment(params), {
    ...options,
    useErrorBoundary: false,
  });
}

export function useRequestServiceAppointmentGuest(
  options?: Omit<
    UseMutationOptions<any, unknown, ServiceAppointment.IRequestServiceAppointmentGuestReq, unknown>,
    'mutationFn'
  >,
) {
  return useMutation(
    (params: ServiceAppointment.IRequestServiceAppointmentGuestReq) => RequestServiceAppointmentGuest(params),
    { ...options },
  );
}
