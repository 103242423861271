import { axiosInstance as axios } from '@/query/axios';

export const getTradeInBrand = async () => {
  try {
    const response = await axios.get(`/user_api/tradein-vehicle/brand`);

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getTradeInModel = async (params: TradeInVehicle.ITradeInVehicleModelReq) => {
  try {
    const response = await axios.get(`/user_api/tradein-vehicle/${params?.brand}/model`);

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getTradeInVariant = async (params: TradeInVehicle.ITradeInVehicleVariantReq) => {
  try {
    const response = await axios.get(`/user_api/tradein-vehicle/${params?.brand}/${params?.model}/variant`);

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getTradeInFuelType = async (params: TradeInVehicle.ITradeInVehicleFuelTypeReq) => {
  try {
    const response = await axios.get(
      `/user_api/tradein-vehicle/${params?.brand}/${params?.model}/${params?.variant}/fueltype`,
    );

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};

export const getTradeInTransmission = async (params: TradeInVehicle.ITradeInVehicleTransmissionReq) => {
  try {
    const response = await axios.get(
      `/user_api/tradein-vehicle/${params?.brand}/${params?.model}/${params?.variant}/${params?.fuelType}/transmission`,
    );

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
