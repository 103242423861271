import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Button from '@components/units/button';
import Modal from '@/components/modules/modals/modal';
import AgreementCheckForm from '@/components/modules/quickMenu/components/modal/components/agreementForm';
import {
  EMPLOYEE_AUTO_SELECT_ID,
  QUICK_MENU_FIELD_DATA,
  QUICK_MENU_MODAL_TITLE,
  QUICK_MODAL_TYPE,
} from '@/components/modules/quickMenu/components/modal/constants';
import quickMenuModalStyle, { consultantMessageStyle } from '@/components/modules/quickMenu/components/modal/style';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import Loading from '@/components/modules/Loading';
import useMember from '@/hooks/useIsMeber';
import { globalAlertPropsSelect } from '@/stores/common';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { useGetDealerDetail, useGetDealersWithAddress } from '@/query/dealers/query';
import { useLeadMutation } from '@/hooks/useLeadMutation';
import { currentConsultantSelector, currentDealerSelector, selfSelectedDealerSelector } from '@/stores/common/user';
import useUpdateUserDealerConsultant from '@/hooks/useUpdateUserDealerConsultant';
import { useRouter } from 'next/router';
import { WWN_HOST } from '@/config';
import { handleQuickMenuGA } from '@/utils/gaCommon';

import { Form } from '@/components/haeLibraryRefactored';
import QuickMenuModalForm from './components/formContents';
import DealershipInfoModal from '@/components/modules/dealershipInfoModal';
import { getDealerAddress } from '@/utils/convert';
import { getDayjsObjWithoutYMD } from '@/utils/getDayjsObjWithoutYMD';
import dayjs from 'dayjs';
import { useSelectBoxModel } from '@/query/product/query';
import { useEmployeeDetailMutation } from '@/query/employee/query';
import checkIsValidInput from '@/components/haeLibraryRefactored/utils/checkIsValidInput';
import { INPUT_VALIDATION_PATTERN } from '@/components/haeLibraryRefactored/constants/pattern';
import useLogout from '@/hooks/useLogout';
import { DEALER_RETAIL_TYPES } from '@/query/dealers/constants';

export type TQuickMenuModalHandle = {
  openQuickMenuModal: (type: TQuickModalType, initialFormData?: IQuickModalOpenInitData) => void;
};

export const getValidModel = (model: number, modelSelectBoxData: Product.ISelectBoxModelsRes[]) => {
  const targetBaseModelId = modelSelectBoxData?.find(data =>
    data.baseModelIds.some(modelId => modelId === Number(model)),
  )?.baseModelIds[0];

  return targetBaseModelId;
};

const regex = /^.*\/quick\/.*/;

/**
 * [HISTORY]
 * 2024.7.22 HMGC-7213 - WWN -> CTB의 QuickMenu 이동 후 AOS 에서 Submit시 동일 QuickModal 순환참조 현상 개선 (연관 컴포넌트 : QuickMenu, Home)
 * 2024.8.1 ICTQMSCHE-5920 - QUICK 모달 닫을때 WWN 메인으로 이동 (퀵모달 닫을때 WWN으로 history.back시 refresh가 되지 않아 로그인 된것 처럼 보이는 현상 변경)
 */
const QuickMenuModal = forwardRef<TQuickMenuModalHandle>(({}, ref, isRefetchMyCtbList = false) => {
  const { t } = useTranslation();

  const router = useRouter();
  const queryClient = useQueryClient();
  const dealerInfoModalRef = useRef<TDealershipInfoModalHandle>(null);
  const [dealerInfo, setDealerInfo] = useState<IDealershipInfoModalProps>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isQuickPath, setIsQuickPath] = useState<boolean>(regex.test(router.asPath));
  const [type, setType] = useState<TQuickModalType>(null);
  const [agreementFormData, setAgreementFormData] = useState<IAgreementFormData>(null);
  const logout = useLogout();
  const { data: modelSelectBoxData } = useSelectBoxModel();

  const isFromWWN = router.query?.fromWWN === 'y';

  const currentDealer = useRecoilValue(currentDealerSelector);
  const currentConsultant = useRecoilValue(currentConsultantSelector);
  const selfSelectedDealer = useRecoilValue(selfSelectedDealerSelector);

  const form = Form.useForm<IQuickMenuFormData>();

  const quickMenuFormData = form.getFieldsValueAll() ?? {};

  /** QuickMenu 중 TestDrive 한해서 GA에 적용한다. */
  const gaSetTestDrive = (
    mergeformList: IQuickMenuMergedFormData,
    quicModaltype: TQuickModalType,
    category: Global.TGaCategory,
  ) => {
    if (quicModaltype === QUICK_MODAL_TYPE.TEST_DRIVE) {
      const modelDisplayName =
        modelSelectBoxData &&
        modelSelectBoxData?.find(d => d?.baseModelIds?.[0] === mergeformList?.model)?.modelDisplayName;

      const quickMenuGaData = {
        ...mergeformList,
        modelDisplayName: modelDisplayName || '',
      };

      return handleQuickMenuGA(quickMenuGaData, quicModaltype, category);
    }
  };

  const { data: dealerDetailData } = useGetDealerDetail({ id: quickMenuFormData?.dealerId });
  const { mutateAsync: getEmployeeDetail } = useEmployeeDetailMutation();

  const { updateStoreCookieForDealerSC } = useUpdateUserDealerConsultant();
  const setAlertProps = useSetRecoilState(globalAlertPropsSelect);

  const [isMember, userProfile] = useMember();

  const mutateOnSuccess = (result: { dealerId: number; employeeId?: number }) => {
    const { dealerId, employeeId } = result;

    const isIoniqRequest =
      type === QUICK_MODAL_TYPE.IONIQ_BASIC ||
      type === QUICK_MODAL_TYPE.IONIQ_LAB ||
      type === QUICK_MODAL_TYPE.IONIQ_LONG_DISTANCE ||
      type === QUICK_MODAL_TYPE.IONIQ_ONE_DAY;

    type !== QUICK_MODAL_TYPE.SERVICE_APPOINTMENT &&
      !isIoniqRequest &&
      dealerId &&
      updateStoreCookieForDealerSC({ dealerId, consultantId: employeeId });

    const alertProps: any = {
      icon: 'ic-popup-confirm',
      okString: t('OK'),
      cancelString: 'contact dealer',
      message: `<div class="content-title">${t('Label.RequestSuccessful')}</div>
          <div>${t('Alert.RequestSuccessfulContent')}</div>`,
      handlerOk: () => {
        setAlertProps(null);
        // CONTACT DEALER인 경우 SUBMIT 확인 후 CTB 메인으로 이동

        if (type === QUICK_MODAL_TYPE.CONTACT_DEALER) {
          window.location.replace(`${WWN_HOST}/${router.query.lang}`);
        }
      },

      displayCloseButton: false,
    };

    if ((type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT || isIoniqRequest) && dealerId) {
      const openDealerShipInfoModal = async (dealer: Dealers.TDealerEntity) => {
        const addressArr = getDealerAddress(dealer);

        const employeeDetail =
          type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT ? null : await getEmployeeDetail(employeeId);

        setDealerInfo({
          hasConsultant: type !== QUICK_MODAL_TYPE.SERVICE_APPOINTMENT,
          // 딜러정보
          dealerCall: dealer?.phoneNumber,
          dealerTitle: dealer?.name,
          distance: null,
          location: (
            <>
              {addressArr[0]}
              <br />
              {addressArr[1]}
            </>
          ),
          time: `${getDayjsObjWithoutYMD(dealer?.openTime).format('hh:mmA')} - ${getDayjsObjWithoutYMD(
            dealer?.closeTime,
          ).format('hh:mmA')}`,
          lat: dealer?.latitude,
          lng: dealer?.longitude,
          name: `${employeeDetail?.firstName ?? ''} ${employeeDetail?.lastName ?? ''}`,
          consultantTitle: t('Label.SalesConsultant'),
          consultantCall: employeeDetail?.mobileNumber,
          email: employeeDetail?.emailAddress,
          lineId: employeeDetail?.lineId,
        });

        dealerInfoModalRef?.current?.openDealershipInfoModal();
      };

      alertProps.handlerCancel = () => {
        setAlertProps(null);
        openDealerShipInfoModal(dealerDetailData?.dealer);
      };
    }

    setAlertProps(alertProps);
    setIsOpen(false);
    queryClient.refetchQueries({ queryKey: ['GET_USER_MYGARAGE'] });

    setIsQuickPath(false);

    if (isFromWWN) router.replace({ query: { lang: router.query.lang } });
  };

  const mutationOptions = useMemo(
    () => ({
      onMutate: () => {
        setIsLoading(true);
      },
      onSettled: () => {
        setIsLoading(false);
      },
      onError: () => {
        setAlertProps({
          okString: t('OK'),
          title: t('Label.RequestFailed'),
          message: t('Alert.RequestFailedTryAgain'),
          handlerOk: () => setAlertProps(null),
          displayCloseButton: false,
        });
      },
    }),
    [],
  );

  const { mutate: mutateLead } = useLeadMutation(mutationOptions, type);
  const { mutate: getDealerWithAddress } = useGetDealersWithAddress(true);

  useImperativeHandle(ref, () => ({
    getIsQuickPath: () => {
      return isQuickPath;
    },
    openQuickMenuModal: (type: TQuickModalType, formData?: IQuickModalOpenInitData) => {
      if (!QUICK_MENU_MODAL_TITLE[type]) return;

      setIsOpen(true);
      setType(type);
      setAgreementFormData(null);

      if (type.includes('ioniq')) {
        logout();

        return;
      }

      const hasDefaultDealerSC = selfSelectedDealer;

      const userFormData =
        isMember && userProfile
          ? {
              firstName: userProfile?.firstName,
              lastName: userProfile?.lastName,
              mobile: userProfile?.phoneNo,
              email: userProfile?.username,
            }
          : {};

      const defaultDealerSC = hasDefaultDealerSC
        ? {
            dealerId: currentDealer?.id,
            employeeId: currentConsultant?.id ?? EMPLOYEE_AUTO_SELECT_ID,
            province: currentDealer?.province,
            district: currentDealer?.district,
            subDistrict: currentDealer?.subDistrict,
          }
        : {};

      const mergedFormData = { ...defaultDealerSC, ...userFormData, ...(formData?.quickMenu ?? {}) };

      if (type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT) {
        mergedFormData.dealerId = null;
        mergedFormData.province = null;
        mergedFormData.district = null;
        mergedFormData.subDistrict = null;
      }

      if (type === QUICK_MODAL_TYPE.TEST_DRIVE || type === QUICK_MODAL_TYPE.QUOTE) {
        mergedFormData.model = getValidModel(mergedFormData.model, modelSelectBoxData);
      }

      form.setFieldsValue(mergedFormData);

      if (formData?.region) {
        getDealerWithAddress({
          ..._.pick(formData.region, ['province', 'district', 'subDistrict']),
          excludeDealershipRetailType:
            type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT ? [DEALER_RETAIL_TYPES.AGENCY_DEALER] : null,
        });
      }

      gaSetTestDrive(quickMenuFormData, type, 'init');
    },
    isOpen: isOpen,
  }));

  const handleClose = () => {
    router?.asPath?.includes('/quick') ? (window.location.href = `${WWN_HOST}/${router.query.lang}`) : setIsOpen(false);
  };

  const handleAgreementChange = (values: Partial<IAgreementFormData>) =>
    setAgreementFormData(prev => ({ ...prev, ...values }));

  const mobile = quickMenuFormData.mobile ?? '';

  const isValidMobile = isMember ? true : mobile.startsWith('0') && mobile.length === 10;

  const isInvalid = useMemo(() => {
    const totalFormData: IQuickMenuMergedFormData = { ...quickMenuFormData, ...agreementFormData };

    const requiredFieldNameList = QUICK_MENU_FIELD_DATA[type]?.requiredFields ?? [];

    const isValidEmail = !totalFormData.email || checkIsValidInput(totalFormData.email, INPUT_VALIDATION_PATTERN.email);

    return requiredFieldNameList.some(name => !totalFormData?.[name]) || !isValidEmail;
  }, [quickMenuFormData, agreementFormData, type]);

  const checkConsultantChange = () => {
    if (
      currentConsultant?.id &&
      currentDealer.id === quickMenuFormData.dealerId &&
      quickMenuFormData.employeeId !== currentConsultant?.id
    )
      return new Promise<boolean>(resolve => {
        const message = (
          <div css={consultantMessageStyle}>
            <div>{t('ConsultantChangeConfirmMessageTop')}</div>
            <div className="consultant-name">{`${currentConsultant?.firstName || ''} ${
              currentConsultant?.lastName || ''
            }`}</div>
            <div>{t('ConsultantChangeConfirmMessageBottom')}</div>
          </div>
        );

        setAlertProps({
          okString: t('OK'),
          cancelString: 'cancel',
          message: message,
          handlerOk: () => {
            setAlertProps(null);
            resolve(true);
          },
          handlerCancel: () => {
            setAlertProps(null);
            resolve(false);
          },
          displayCloseButton: false,
        });
      });

    return true;
  };

  const handleSubmit = async () => {
    const allowConsultantChange = await checkConsultantChange();

    if (!allowConsultantChange) return;

    const isIoniqRequest = type.toUpperCase().includes('IONIQ');

    const mergedFormData: IQuickMenuMergedFormData = {
      ...quickMenuFormData,
      ...agreementFormData,
      comment: isIoniqRequest
        ? `${t(QUICK_MENU_MODAL_TITLE[type])}\n\n${quickMenuFormData.comment || ''}`
        : quickMenuFormData.comment,
    };

    mutateLead(mergedFormData, {
      onSuccess: (result: { dealerId: number; employeeId?: number }) => {
        mutateOnSuccess(type === QUICK_MODAL_TYPE.SERVICE_APPOINTMENT ? { dealerId: mergedFormData.dealerId } : result);
        gaSetTestDrive(mergedFormData, type, 'complete');
      },
    });
  };

  return (
    <>
      <Modal
        backDropClose={false}
        visible={isOpen}
        closeModal={handleClose}
        size="large-popup"
        modalTitle={t(QUICK_MENU_MODAL_TITLE[type])}
        css={quickMenuModalStyle}
        modalWrapperClassName="quick-menu-modal"
        footer={
          <div className="submit-button-box">
            <Button disabled={isInvalid || !isValidMobile} onClick={handleSubmit}>
              {t('Label.Submit')}
            </Button>
          </div>
        }
      >
        <div className="quick-menu-form-box">
          <div className="form-container">
            <QuickMenuModalForm type={type} form={form} />
            <div className="form-wrapper">
              <AgreementCheckForm handleChange={handleAgreementChange} />
            </div>
          </div>
        </div>
        {isLoading && <Loading />}
      </Modal>
      <DealershipInfoModal ref={dealerInfoModalRef} {...(dealerInfo ?? {})} />
    </>
  );
});

QuickMenuModal.displayName = 'QuickMenuModal';

export default QuickMenuModal;
