import { useQuery } from '@tanstack/react-query';
import { getTradeInBrand, getTradeInFuelType, getTradeInModel, getTradeInTransmission, getTradeInVariant } from './api';

export const useTradeInBrand = () => {
  return useQuery<TradeInVehicle.ITradeInVehicleBrandRes, Error>(['TRADEIN_BRAND'], () => getTradeInBrand());
};

export const useTradeInModel = (params: TradeInVehicle.ITradeInVehicleModelReq) => {
  return useQuery<TradeInVehicle.ITradeInVehicleModelRes, Error>(
    ['TRADEIN_MODEL', params],
    () => getTradeInModel(params),
    {
      enabled: !!params?.brand,
    },
  );
};

export const useTradeInVariant = (params: TradeInVehicle.ITradeInVehicleVariantReq) => {
  return useQuery<TradeInVehicle.ITradeInVehicleVariantRes, Error>(
    ['TRADEIN_VARIANT', params],
    () => getTradeInVariant(params),
    {
      enabled: !!params?.brand && !!params?.model,
    },
  );
};

export const useTradeInFuelType = (params: TradeInVehicle.ITradeInVehicleFuelTypeReq) => {
  return useQuery<TradeInVehicle.ITradeInVehicleFuelTypeRes, Error>(
    ['TRADEIN_FUEL', params],
    () => getTradeInFuelType(params),
    {
      enabled: !!params?.brand && !!params?.model && !!params?.variant,
    },
  );
};

export const useTradeInTransmission = (params: TradeInVehicle.ITradeInVehicleTransmissionReq) => {
  return useQuery<TradeInVehicle.ITradeInVehicleTransmissionRes, Error>(
    ['TRADEIN_TRANSMISSION'],
    () => getTradeInTransmission(params),
    {
      enabled: !!params?.brand && !!params?.model && !!params?.fuelType && !!params?.variant,
    },
  );
};
