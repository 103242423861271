import { UseQueryResult, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getConfiguration,
  getFilterVariants,
  getModels,
  getSelectBoxModels,
  getSelectBoxVariants,
  getVariantColor,
  getVariantDetail,
  getVariants,
  getVariantsConfigure,
} from './api';

export const useModels = (params: Product.IModelsReq, options?: { onSettled?: () => void; onError?: () => void }) => {
  return useQuery<Product.TModelsRes>(['MODELS', params], () => getModels(params), {
    ...(options ?? {}),
    keepPreviousData: true,
  });
};

export const useVariants = (
  params: Product.IVariantsReq,
  options?: { onSettled?: () => void; onError?: () => void },
) => {
  return useQuery<Product.TVariantsRes, Error>(['VARIANTS', params], () => getVariants(params), {
    ...(options ?? {}),
    keepPreviousData: true,
    // !!params.categoryCode &&
    // !!params.categoryType &&
    // !!params.variantFilterDto &&
    // Object.keys(params.variantFilterDto).length !== 0,
  });
};

export const useFilterVariants = (
  params: Product.IFilterVariantsReq,
  options?: { onSettled?: () => void; onError?: () => void },
) => {
  return useQuery<Product.TVariantsRes, Error>(['FILTER_VARIANTS', params], () => getFilterVariants(params), {
    ...(options ?? {}),
    keepPreviousData: true,
    enabled: !!params.variantFilterDto && Object.keys(params.variantFilterDto).length !== 0,
  });
};

export const useSelectBoxModel = (
  params?: Product.ISelectBoxModelsReq,
  options?: { enabled?: boolean; keepPreviousData?: boolean },
) => {
  return useQuery<Product.ISelectBoxModelsRes[]>(['SELECTBOX_MODELS', params], () => getSelectBoxModels(params), {
    enabled: options?.enabled,
    keepPreviousData: options?.keepPreviousData,
  });
};

export const useSelectBoxModels = (params: Product.ISelectBoxModelsReq[]) => {
  const resultQueries: UseQueryResult<Product.ISelectBoxModelsRes[]>[] = useQueries({
    queries: params?.map((param: Product.ISelectBoxModelsReq) => {
      return {
        queryKey: ['SELECTBOX_MODELS', param],
        queryFn: () => getSelectBoxModels(param),
        keepPreviousData: true,
        enabled: params?.filter(d => d)?.length > 0,
      };
    }),
  });

  return {
    data: resultQueries?.map((item: UseQueryResult<Product.ISelectBoxModelsRes[]>) => item.data),
  };
};
export const useSelectBoxVariants = (params: Product.ISelectBoxVariantsReq[]) => {
  const resultQueries: UseQueryResult<Product.ISelectBoxVariantsRes[]>[] = useQueries({
    queries: params?.map((param: Product.ISelectBoxVariantsReq) => {
      return {
        queryKey: ['SELECTBOX_VARIANTS', param],
        queryFn: () => getSelectBoxVariants(param),
        keepPreviousData: true,
        enabled: param?.baseModelIds?.filter(d => !!d)?.length > 0 && !!param?.baseModelIds,
      };
    }),
  });

  return {
    data: resultQueries?.map((item: UseQueryResult<Product.ISelectBoxVariantsRes[]>) => item.data),
  };
};

// configure
export const useVariantsConfigure = (params: Product.IVariantConfigureReq) => {
  return useQuery(['VARIANTS_CONFIGURE', params], () => getVariantsConfigure(params), {
    enabled: !!params.baseModelId && !!params.dealerId,
  });
};
export const useVariantDetail = (productNo: number) => {
  return useQuery(['VARIANT_DETAIL', productNo], () => getVariantDetail(productNo), {
    enabled: !!productNo,
  });
};

export const useExteriorColor = () => {
  return useMutation((params: Omit<Product.IExteriorColorReq, 'type'>) =>
    getVariantColor({ type: 'exterior', ...params }),
  );
};

export const useInteriorColor = () => {
  return useMutation((params: Omit<Product.IInteriorColorReq, 'type'>) =>
    getVariantColor({ type: 'interior', ...params }),
  );
};

export const useSelectBoxVariant = (params: Product.ISelectBoxVariantsReq) => {
  return useQuery<Product.TSelectBoxVariantRes, Error>(
    ['SELECT_BOX_VARIANT', params],
    () => getSelectBoxVariants(params),
    {
      enabled: !!params?.baseModelIds && params?.baseModelIds.length !== 0,
    },
  );
};

export const useConfigurationList = (params: Product.TConfigurationReq) => {
  return useQuery(['CONFIGURATION', params], () => getConfiguration(params), {
    enabled: !!params.dealerId && !!params.baseModelId,
  });
};
