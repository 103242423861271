import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import QuickMenuFormItem from '../contentsFormItem';
import DatePicker from '@/components/units/datePicker';
import { Dropdown, Input } from '@/components/haeLibraryRefactored';
import DealerBlock from '@/components/modules/dealerBlock';
import { DropdownItem } from '@/components/haeLibraryRefactored/Dropdown/type';
import RegionSelectFormContents from '@/components/modules/regionSelectForm/components/contents';
import useFormInstance from '@/components/haeLibraryRefactored/Form/hooks/useFormInstance';
import { useSelectBoxModel } from '@/query/product/query';
import QuickMenuModalContext from '@/components/modules/quickMenu/components/modal/context';
import classNames from 'classnames';
import { getDayjsObjWithoutYMD } from '@/utils/getDayjsObjWithoutYMD';
import { EMPLOYEE_AUTO_SELECT_ID, QUICK_MODAL_TYPE } from '@/components/modules/quickMenu/components/modal/constants';
import dayjs from 'dayjs';
import { useGetDealerDetail } from '@/query/dealers/query';
import { currentConsultantState, currentDealerState, memberCheckState } from '@/stores/common/user';
import { useRecoilValue } from 'recoil';
import useMember from '@/hooks/useIsMeber';

interface IIoniqFormContentsProps {}

/**
 * [HISTORY]
 * 2024.7.26 HMGC-7282 - IONIQ5 딜러 ID 검증계도 고정으로 변경, IONIQ 다국어 적용 (검증계 - WWN -> IONIQ Lab 신청폼 딜러 미맵핑으로 Submit시 에러 발생에 대한 대응)
 * 2024.7.31 ICTQMSCHE-5838 - WWN 로그인 -> IONIQ 모달 신청시 콤보박스 미맵핑 현상 개선, 로그인 -> IONIQ 신청모달 띄울때 로그아웃 처리되는 현상은 정상 사양(ICTQMSCHE-5832 참고)
 */
function IoniqFormContents({}: IIoniqFormContentsProps) {
  const { t } = useTranslation();

  const form = useFormInstance<IQuickMenuFormData>();
  const formData = form.getFieldsValueAll() ?? {};

  const [isMember] = useMember();
  const { dealerId, province, district, subDistrict } = formData;
  const currentDealer = useRecoilValue(currentDealerState);
  const currentConsultant = useRecoilValue(currentConsultantState);

  const { data: modelSelectBoxData, isFetched: isModelFetched } = useSelectBoxModel();

  const ioniqData = modelSelectBoxData?.find(item => item.modelDisplayName.toUpperCase().includes(t('IONIQ')));

  const { modalType, dealerList: baseDealerList, consultantList } = useContext(QuickMenuModalContext);

  const ioniqDealerId = Number(process.env.NEXT_PUBLIC_DEALER_ID_IONIQ5);

  const ioniqBaseModelId = ioniqData?.baseModelIds?.[0];

  const { data: ioniqDealer, isFetched } = useGetDealerDetail({ id: ioniqDealerId });

  const modelList = useMemo(
    () =>
      modelSelectBoxData?.map(item => ({
        value: item.baseModelIds?.[0],
        label: item.modelDisplayName,
      })),
    [modelSelectBoxData],
  );

  const dealerList = baseDealerList.filter(dealer => dealer.id === ioniqDealerId);

  useEffect(() => {
    if (isFetched && isModelFetched) {
      const newEmployeeId =
        currentDealer?.id !== ioniqDealerId || !currentConsultant?.id ? EMPLOYEE_AUTO_SELECT_ID : currentConsultant?.id;

      form.setFieldsValue({
        ...formData,
        dealerId: ioniqDealerId,
        province: ioniqDealer?.dealer?.province,
        district: ioniqDealer?.dealer?.district,
        subDistrict: ioniqDealer?.dealer?.subDistrict,
        model: ioniqBaseModelId,
        employeeId: newEmployeeId,
        returnTime: 1,
      });
    }
  }, [isMember, isFetched, isModelFetched]);

  const preferredTime = useMemo(() => {
    if (modalType === QUICK_MODAL_TYPE.IONIQ_BASIC)
      return [
        { value: '10:30AM ~ 12:00PM', label: '10:30AM ~ 12:00PM' },
        { value: '12:30PM ~ 1:30PM', label: '12:30PM ~ 1:30PM' },
        { value: '1:30PM ~ 3:00PM', label: '1:30PM ~ 3:00PM' },
        { value: '3:00PM ~ 4:30PM', label: '3:00PM ~ 4:30PM' },
        { value: '4:30PM ~ 6:00PM', label: '4:30PM ~ 6:00PM' },
      ];
    else if (modalType === QUICK_MODAL_TYPE.IONIQ_LONG_DISTANCE)
      return [
        { value: '10:30AM ~ 12:00PM', label: '10:30AM ~ 12:00PM' },
        { value: '12:30PM ~ 1:30PM', label: '12:30PM ~ 1:30PM' },
        { value: '1:30PM ~ 3:00PM', label: '1:30PM ~ 3:00PM' },
        { value: '3:00PM ~ 4:30PM', label: '3:00PM ~ 4:30PM' },
        { value: '4:30PM ~ 6:00PM', label: '4:30PM ~ 6:00PM' },
      ];
    else if (modalType === QUICK_MODAL_TYPE.IONIQ_ONE_DAY)
      return [
        { value: '1:00PM ~ 2:00PM', label: '1:00PM ~ 2:00PM' },
        { value: '2:00PM ~ 3:00PM', label: '2:00PM ~ 3:00PM' },
        { value: '3:00PM ~ 4:00PM', label: '3:00PM ~ 4:00PM' },
        { value: '4:00PM ~ 5:00PM', label: '4:00PM ~ 5:00PM' },
        { value: '5:00PM ~ 6:00PM', label: '5:00PM ~ 6:00PM' },
        { value: '6:00PM ~ 7:00PM', label: '6:00PM ~ 7:00PM' },
        { value: '7:00PM ~ 8:00PM', label: '7:00PM ~ 8:00PM' },
      ];

    return [];
  }, []);

  return (
    <>
      <div className="form-wrapper">
        <div className="form-top-box">
          <legend className="hidden">
            <div className="title-box">
              <span className="title"></span>
            </div>
          </legend>
          <div className="required-noti-box">
            <span className="text">{t('Label.RequiredFields')}</span>
          </div>
        </div>
        <fieldset>
          <div className="field-container">
            <div className="field-area">
              <QuickMenuFormItem className="col-2" required label={t('Label.FirstName')} name="firstName">
                <Input placeholder={t('Label.EnterYourFirstName')} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" required label={t('Label.LastName')} name="lastName">
                <Input placeholder={t('Label.EnterYourLastName')} />
              </QuickMenuFormItem>
              <QuickMenuFormItem
                className="col-2"
                label={t('Label.MobilePhone')}
                name="mobile"
                validation={(val: string) => {
                  const status = (val.startsWith('0') && val.length >= 10) || val?.length === 0 ? 'success' : 'error';

                  return {
                    status,
                    message: status === 'error' && 'Invalid phone number',
                  };
                }}
                required
              >
                <Input type="number" placeholder={t('Label.EnterYourMobilePhone')} />
              </QuickMenuFormItem>
              <QuickMenuFormItem className="col-2" label={t('Label.Email')} name="email">
                <Input placeholder={t('Label.EnterYourEmail')} type="email" />
              </QuickMenuFormItem>
              {modalType !== QUICK_MODAL_TYPE.IONIQ_LAB && (
                <QuickMenuFormItem className="col-1" required name="model" label={t('TestDriveModel')}>
                  <Dropdown disabled placeholder={t('Label.SelectAModel')} items={modelList} />
                </QuickMenuFormItem>
              )}
            </div>
          </div>
        </fieldset>
      </div>
      <div className="form-wrapper">
        <RegionSelectFormContents
          type={modalType}
          provinceList={[province]}
          districtList={[district]}
          subDistrictList={[subDistrict]}
          disabled={true}
        />
        <fieldset>
          <div className="field-container">
            <div className="field-area">
              <QuickMenuFormItem
                className={classNames('col-2', 'dealer-field')}
                name="dealerId"
                label={t('Label.Dealer')}
              >
                <Dropdown
                  placeholder={t('Label.SelectADealer')}
                  items={dealerList}
                  itemRender={(item: DropdownItem) => (
                    <DealerBlock
                      key={item.id}
                      title={item.name}
                      noCallAction
                      call={item.phoneNumber}
                      location={`${item.province} ${item.district} ${item.subDistrict}`}
                      distance={item?.distance}
                      time={`${getDayjsObjWithoutYMD(item.openTime).format('hh:mmA')} - ${getDayjsObjWithoutYMD(
                        item.closeTime,
                      )?.format('hh:mmA')}`}
                      dealerCode={item?.dealerCode}
                    />
                  )}
                />
              </QuickMenuFormItem>
              <QuickMenuFormItem
                className={classNames('col-2', 'employee-field')}
                name="employeeId"
                label={t('Label.SelectASalesConsultant')}
              >
                <Dropdown placeholder={t('Label.SelectASalesConsultant')} disabled={!dealerId} items={consultantList} />
              </QuickMenuFormItem>
              {modalType !== QUICK_MODAL_TYPE.IONIQ_LAB && (
                <>
                  <QuickMenuFormItem className="col-2" name="preferredDate" label={t('PreferredVisitDate')}>
                    <DatePicker minDate={dayjs(new Date()).toDate()} />
                  </QuickMenuFormItem>
                  <QuickMenuFormItem
                    className="col-2"
                    name="preferredTimeToCall"
                    label={
                      modalType === QUICK_MODAL_TYPE.IONIQ_ONE_DAY ? t('PreferredPickupTime') : t('PreferredVisitTime')
                    }
                  >
                    <Dropdown placeholder={t('Label.SelectATime')} items={preferredTime} />
                  </QuickMenuFormItem>
                </>
              )}
              {modalType === QUICK_MODAL_TYPE.IONIQ_ONE_DAY && (
                <QuickMenuFormItem className="col-2" name="returnTime" label={t('ReturnTime')}>
                  <Dropdown
                    disabled
                    value={1}
                    placeholder={t('Label.SelectATime')}
                    items={[{ value: 1, label: '10:00 AM ~ 11:00 AM (fixed)' }]}
                  />
                </QuickMenuFormItem>
              )}
              <QuickMenuFormItem name="comment">
                <Input placeholder={t('Label.Comments')} type="textarea" maxByte={3900} showCount />
              </QuickMenuFormItem>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
}

export default IoniqFormContents;
